
body {
	&:before {
		display:block;
		content:'';
		position: fixed;
		background-color: #000;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height:100%;
		z-index:1500;
		top:0px;
		left:0px;
	}
	&:after {
		display:block;
		content:'';
		position: fixed;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url(/source/dist/images/logo_navbar.svg);
		background-size: 100% 100%;
		width: 100px;
		height:100px;
		z-index:2000;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
	}
}
